import React, { useContext, useState } from 'react'
import { BaseContext } from '../context/BaseProvider';
import { DEFAULT_CHAIN, SUPPORTED_CHAIN, contract } from '../helper/constant';
import { useNetwork } from 'wagmi';

export default function CreateSection() {
    const { chain } = useNetwork();
    const [serror, setSerror] = useState('');
    const [derror, setDerror] = useState('');
    const {
        createType,
        name,
        setName,
        symbol,
        setSymbol,
        decimals,
        setDecimals,
        supply,
        setSupply,
        burn,
        setBurn,
        mint,
        setMint,
        taxFees,
        setTaxFees,
        buytax,
        setBuytax,
        selltax,
        setSelltax,
        sameOwner,
        setSameOwner,
        mwalletaddress,
        setMwalletaddress,
        presale,
        setPresale,
        airdrop,
        setAirdrop,
        isv3,
        setIsv3,
        poolfees,
        setPoolfees
    } = useContext(BaseContext);

    const handleSupplyChange = (number) => {


        var regex = /^-?[0-9]*$/;
        if (number <= 0 || number === '') {
            setSupply(number)
            setSerror('Please enter valid token supply')
        }
        else if (regex.test(number)) {
            setSupply(number)
            setSerror('');
        }
        else {
            setSerror('Please enter valid token supply')
        }

    }

    const handleDecimalsChange = (number) => {
        var regex = /^[0-9\b]+$/;
        if (number <= 0 || number === '') {
            setDecimals(number)
            setDerror('Please enter valid decimals')
        }
        else if (regex.test(number)) {
            setDecimals(number > 18 || number === 0 ? 18 : number)
            setDerror('');
        }
        else {
            setDerror('Please enter valid decimals')
        }
    }
    

    return (
        <div className='row d-flex justify-content-center mt-1 bg-card mx-0'>
            <h3 className='text-warning text-center mt-3'>{
                createType === 0 ? 'Basic Token' :
                    createType === 1 ? 'Standard Token' :
                        createType === 2 ? 'Premium Token' : ''
            }</h3>
            <div className='col-11 col-md-10 col-xl-5 mt-1'>
                <h5>Enter Token Parameters</h5>
                <form>
                    <div class="form-group">
                        <label for="symbol">Token Name</label>
                        <input value={name} onChange={(e) => setName(e.target.value)} type="text" class="form-control" id="symbol" placeholder="Enter token name (Ex. Bitcoin)" />
                    </div>

                    <div class="form-group">
                        <label for="name">Token Symbol</label>
                        <input value={symbol} onChange={(e) => setSymbol(e.target.value)} type="text" class="form-control" id="name" placeholder="Enter token symbol (Ex. BTC)" />
                    </div>

                    <div class="form-group">
                        <label for="supply">Token Supply</label>
                        <input value={supply} onChange={(e) => handleSupplyChange(e.target.value)} type="text" class="form-control" id="supply" placeholder="Enter token supply (Ex.21000000)" />
                        <span className='text-sm text-danger'>{serror}</span>
                    </div>



                    <div class="form-group">
                        <label for="decimals">Token Decimals</label>
                        <input value={decimals} onChange={(e) => handleDecimalsChange(e.target.value)} type="text" class="form-control" id="decimals" placeholder="Enter token decimals (Ex. 18)" />
                        <span className='text-sm text-danger'>{derror}</span>
                    </div>

                    <p className='text-bold'>Special Features</p>

                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input text-warning" type="checkbox" id="burn" onChange={() => setBurn(!burn)} checked={burn} />
                            <label class="form-check-label" for="burn">Burn</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input text-warning" type="checkbox" id="mint" onChange={() => setMint(!mint)} checked={mint} />
                            <label class="form-check-label" for="mint">Mint</label>
                        </div>

                        {createType === 1 &&
                            <>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input text-warning" type="checkbox" id="presale" onChange={() => setPresale(!presale)} checked={presale} />
                                    <label class="form-check-label" for="presale">Presale</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input text-warning" type="checkbox" id="airdrop" onChange={() => setAirdrop(!airdrop)} checked={airdrop} />
                                    <label class="form-check-label" for="airdrop">Airdrop</label>
                                </div>
                            </>
                        }
                        {createType === 1 &&
                            <>
                                {presale &&
                                    <div className='row bg-body-card p-3 mt-3 d-md-flex align-items-center'>
                                        <div className='col-12'>
                                            <div>
                                                <span>Presale: </span><br />
                                                <div className='inside-tab'>
                                                    <span className='text-sm'>
                                                        Presale is a function for the IDO presale system. Project owners can set the price via Smart contract and change the price at any time.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {airdrop &&
                                    <div className='row bg-body-card p-3 mt-3 d-md-flex align-items-center'>
                                        <div className='col-12 col-md-12'>
                                            <div>
                                                <span>Airdrop: </span><br />
                                                <div className='inside-tab'>
                                                    <span className='text-sm'>
                                                        Airdrop is an automatic token distribution function with smart contracts. Owners can customize distribution to each address and set up a referral system.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }

                        {createType === 2 &&
                            <>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input text-warning" type="checkbox" id="tax_fees" onChange={() => setTaxFees(!taxFees)} checked={taxFees} />
                                    <label class="form-check-label" for="tax_fees">Tax Fees</label>
                                </div>
                                {/* <div class="form-check form-check-inline">
                                    <input class="form-check-input text-warning" type="checkbox" id="tax_fees" onChange={() => setIsv3(false)} checked={!isv3} />
                                    <label class="form-check-label" for="tax_fees">V2</label>
                                </div>
                                { contract[chain && chain.id && SUPPORTED_CHAIN.includes(chain.id) ? chain.id : DEFAULT_CHAIN].ISV3SUPPORT &&
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input text-warning" type="checkbox" id="tax_fees" onChange={() => setIsv3(true)} checked={isv3} />
                                        <label class="form-check-label" for="tax_fees">V3</label>
                                    </div>
                                } */}
                            </>
                        }
                    </div>
                    {createType === 2 &&
                        <>
                            {taxFees &&
                                <>
                                    {isv3 &&
                                        <div className='row bg-body-card p-3 mt-3 d-md-flex align-items-center'>
                                            <div className='col-12 col-md-8'>
                                                <div>
                                                    <span>Pool Fees : </span><br />
                                                    <div className='inside-tab'>
                                                        <span className='text-sm'>
                                                            multiple pools for each token pair, each with a different swapping fee. Liquidity providers may initially create pools at three fee levels: 0.05%, 0.30%, and 1%. More fee levels may be added by UNI governance.
                                                            for more information refer to link - <a href='https://docs.uniswap.org/concepts/protocol/fees' target='_blank'>uniswap fees</a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-4'>
                                                {contract[chain && chain.id ? chain.id : DEFAULT_CHAIN].FEES_POOL &&
                                                    <select class="form-select" aria-label="Default select example" onChange={(e) => setPoolfees(e.target.value)}>
                                                        {contract[chain && chain.id ? chain.id : DEFAULT_CHAIN].FEES_POOL.map((t,k)=>{
                                                            return(
                                                                <option value={t} selected={t === poolfees}>{t}</option>
                                                            )
                                                        })}
                                                    </select>
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className='row bg-body-card p-3 mt-3 d-md-flex align-items-center'>
                                        <div className='col-12 col-md-8'>
                                            <div>
                                                <span>Buy Tax : </span><br />
                                                <div className='inside-tab'>
                                                    <span className='text-sm'>
                                                        A percentage buy tax will be sent to smart contract waiting for value to be convrted to payble token
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-4'>
                                            <div class="input-group">
                                                <input type="text" class="form-control" onChange={(e) => setBuytax(e.target.value)} name="buytax" placeholder="0.00" value={buytax} />
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row bg-body-card p-3 mt-3 d-md-flex align-items-center'>
                                        <div className='col-12 col-md-8'>
                                            <div>
                                                <span>Sell Tax : </span><br />
                                                <div className='inside-tab'>
                                                    <span className='text-sm b'>
                                                        A percentage sell tax will be sent to smart contract waiting for value to be convrted to payble token
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-4'>
                                            <div class="input-group">
                                                <input type="text" class="form-control" onChange={(e) => setSelltax(e.target.value)} name="selltax" placeholder="0.00" value={selltax} />
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row bg-body-card p-3 mt-3 d-md-flex align-items-center'>
                                        <div className='col-12'>
                                            <label for="marketing">Marketing Wallet :</label><br />

                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input text-warning" type="checkbox" id="sameowner" onChange={() => setSameOwner(!sameOwner)} checked={sameOwner} />
                                                <label class="form-check-label" for="sameowner">Use Same Onwer</label>
                                            </div>
                                            {!sameOwner &&
                                                <div>
                                                    <input value={mwalletaddress} onChange={(e) => setMwalletaddress(e.target.value)} type="text" class="form-control" placeholder="Enter marketing wallet address" />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </form>
            </div>
        </div>
    )
}
