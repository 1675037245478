import React, { useEffect, useState } from 'react';
import { contract, IS_MAINNET, trimAddress } from '../helper/constant';
import { api } from '../services/api';

export default function LastReferral() {
    const [referral, setReferral] = useState([]);

    useEffect(() => {
        async function fetch() {
            let formData = new FormData();
            formData.append("request_type", 'get_history');
            formData.append("sub_type", 'referrals');
            formData.append("is_mainnet", IS_MAINNET ? 1 : 2);
            api.post(`/`, formData).then((response) => {
                if (response && response.data && response.data.data && response.data.status === "success" && response.data.data.referral_data) {
                    setReferral(response.data.data.referral_data)
                }
                else {
                    console.log(response.data.message);
                }
            });
        }

        fetch();
    }, []);


    return (
        <div className='col-12 col-md-5 py-3 px-3 border-radius-2xl bg-body-card'>
            <h5>Last Referral Bonus</h5>
            <div className='border-dark px-4 mt-4'>
                {referral && referral.length > 0 ? (
                    referral.map((rdata, k) => {
                        return (
                            <div className={`row d-flex align-items-center py-2 ${parseInt(k + 1) < parseInt(referral.length) ? 'border-bottom' : ''}`} key={k}>
                                <div className='col-9 col-md-10 col-xl-9'>
                                    <img src={contract[rdata.chain ? rdata.chain : DEFAULT_CHAIN].img} alt='binanceImg' height="30px" width="30px" />
                                    <span className='mx-2 text-sm'>{rdata.tx_hash ? trimAddress(rdata.tx_hash) : ' - '}</span>
                                </div>
                                <div className='col-3 col-md-2 col-xl-3 d-flex justify-content-end'>
                                    <a target='_blank' href={`${contract[rdata.chain ? rdata.chain : DEFAULT_CHAIN].EXPLORE}address/${rdata.r_address ? rdata.r_address : ''}`} className='btn btn-sm btn-transparent mb-0 me-1'>
                                        View
                                    </a>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div className='row d-flex pl-4 align-items-center py-2 border-bottom text-center'>
                        <div className='col-12 d-flex justify-content-center'>
                            No record Found
                        </div>

                    </div>
                )}
            </div>
        </div>
    )
}
