import React, { useContext, useState } from 'react';
import metaImg from '../images/metamask.png';
import { BaseContext } from '../context/BaseProvider';
import { DEFAULT_CHAIN, contract, trimAddress } from '../helper/constant';
import { useNetwork } from 'wagmi';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Countdown, { zeroPad } from 'react-countdown';


export default function CreatedSection() {
    const { chain } = useNetwork();
    const { name, symbol, decimals, supply, tokenAddress, createHash, tokenData  } = useContext(BaseContext);
    const [refcopy, setRefcopy] = useState(false);

    const addToken = async () => {
        try {
            // 'wasAdded' is a boolean. Like any RPC method, an error can be thrown.
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: tokenAddress, // The address of the token.
                        symbol: symbol, // A ticker symbol or shorthand, up to 5 characters.
                        decimals: decimals // The number of decimals in the token.
                    },
                },
            });

            if (wasAdded) {
                console.log('Thanks for your interest!');
            } else {
                console.log('Your loss!');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return '00:00';
        } else {
          // Render a countdown
          return <span>{zeroPad(minutes , 2)}:{zeroPad(seconds , 2)}</span>;
        }
      };

    return (
        <div className='row d-flex justify-content-center mt-1 bg-card mx-0'>
            <h5 className='text-success text-center mt-5'>Your token was created successfully</h5>
            {tokenData  && tokenData.created_at && tokenData.verify === 2 &&
                <p className='text-center text-warning'>Your Smart contract verify in : <Countdown date={ parseInt(tokenData.time) + parseInt(600000)} renderer={renderer} /></p>
            }
            {tokenData && tokenData.verify && tokenData.verify === 1 &&
                <p className='text-center text-success'>Your Smart contract successfully verified</p>

            }
            <div className='col-12 col-md-6 mt-2'>
                <div className='d-flex justify-content-between border-bottom mb-2'>
                    <span>Name:</span>
                    <span>{name ? name : ' - '}</span>
                </div>
                <div className='d-flex justify-content-between border-bottom mb-2'>
                    <span>Symbol:</span>
                    <span>{symbol ? symbol : ' - '}</span>
                </div>
                <div className='d-flex justify-content-between border-bottom mb-2'>
                    <span>Total Supply:</span>
                    <span>{supply ? supply : ' - '}</span>
                </div>
                <div className='d-flex justify-content-between border-bottom mb-2'>
                    <span>Decimals:</span>
                    <span>{decimals ? decimals : ' - '}</span>
                </div>
                <div className='d-flex justify-content-between border-bottom mb-2'>
                    <span>Token Address:</span>
                    <span>{trimAddress(tokenAddress)}</span>
                </div>
                <div className='d-flex justify-content-lg-center mb-4 mt-3'>
                    <a target='_blank' href={`${chain && contract[chain.id] ? contract[chain.id].EXPLORE : contract[DEFAULT_CHAIN].EXPLORE}tx/${createHash}`} className='bg-warning text-dark btn btn-primary mb-0 me-1 mt-2 mt-md-0'>
                        View transaction
                    </a>
                    <CopyToClipboard text={tokenAddress} onCopy={() => {
                        setRefcopy(true);
                        setTimeout(() => {
                            setRefcopy(false);
                        }, 2000)
                    }}>
                        <button className='bg-warning text-dark btn btn-primary mb-0 me-1 mt-2 mt-md-0'>
                            {refcopy ? 'Copied' : 'Copy address'}
                        </button>
                    </CopyToClipboard>
                    <button className='bg-warning text-dark btn btn-primary mb-0 me-1 mt-2 mt-md-0' onClick={() => {
                        addToken()
                    }}>
                        <img src={metaImg} alt="metaImg" height="24px" width="24px" className='mx-2' />
                        Add wallet
                    </button>
                </div>
            </div>
        </div>
    )
}
